@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  font-family: "Chakra Petch", serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000  ;
  font-weight: 400;
  overflow-x: hidden;
}

main {
  background: #FFFFFF;
  background-image: url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/37f002aa-032d-480b-9aa7-474adef47ed6/daqohse-f79cb156-0096-4748-9f7e-39b15cf2e86e.gif?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOjdlMGQxODg5ODIyNjQzNzNhNWYwZDQxNWVhMGQyNmUwIiwiaXNzIjoidXJuOmFwcDo3ZTBkMTg4OTgyMjY0MzczYTVmMGQ0MTVlYTBkMjZlMCIsIm9iaiI6W1t7InBhdGgiOiJcL2ZcLzM3ZjAwMmFhLTAzMmQtNDgwYi05YWE3LTQ3NGFkZWY0N2VkNlwvZGFxb2hzZS1mNzljYjE1Ni0wMDk2LTQ3NDgtOWY3ZS0zOWIxNWNmMmU4NmUuZ2lmIn1dXSwiYXVkIjpbInVybjpzZXJ2aWNlOmZpbGUuZG93bmxvYWQiXX0.J-x32M2EVAjG7wBPe_hvxGgyKH1dUGhBaoYqYjhUXW4');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 725px;
}

a {
  text-decoration: none;
  color: inherit;
  background-color: transparent;
  transition: 0.2s ease-in;

}

ul,
ol {
  list-style: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 10%;
  font-weight: normal;
}

img,
video {
  display: block;
  max-width: 100%;
}

.btn {
  border-radius: 70px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  outline: none;
  float: right;
}

.btn-shadow{
  box-shadow: 0px 1px 7px rgb(255, 255, 255);

}

.btn-shadow:hover{
  box-shadow: 0px 3px 8px rgb(255, 255, 255);
}

.PrimaryBtn { /* resume button*/
  padding: 12px 20px;
  border: none;
  background: #66dbfa;
  color: #000000;
}



.SecondaryBtn{
  padding: 10px 18px;
  font-size: x-large;
  background-color: #66dbfa;
  color: #000;
  margin-right: 1rem;
  position: absolute;
  right: 70px;
  margin-top: auto;
}

.PrimaryBtn:hover {
  background: #fff;
}

.SecondaryBtn:hover {
  background: #fff;
  color: #000000
}

.Container {
  max-width: 1000px;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.BigCard {
  padding-top: 3rem;
  font-size: 30px;
  padding-bottom: 3rem;
  padding-right: 2rem;
  padding-left: 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  background-color: #000;
  box-shadow: #66dbfa 0px 5px 50px;
}

.AboutBio{
  text-align: justify;
  max-width: 750px;
  margin: 0 auto;
  color: #fff;
  line-height: 1.6;
  font-size: 20px;
}

.tagline2 {
  text-align: left;
  max-width: 650px;
  margin: 0;
  color: #fff;
  line-height: 1.6;
}

.tagline2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.SectionTitle {
  font-size: 50px;
  color: #FFFFFF;
  font-weight: 700;
  margin-bottom: 3.5rem;
}
.ProjectWrapper {
  margin-top: 0px;
}



/* ------------------------------------ Media for Container */
@media (max-width: 1000px) {
  .Container {
    padding: 0;
  }
}
