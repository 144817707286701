 .app {
    text-align: center;
    transition: background-color 0.5s, color 0.5s;
  }
  
  .app.light {
    background-color: white;
    color: black;
  }
  
  .app.dark {
    background-color: black;
    color: white;
  }
  
  button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  